* {
  box-sizing: border-box;
}

/* Global Styles */
.App {
  height: 100%;
}
.container {
  display: flex;
  height: 100%;
}
.App-content {
  flex: 1;
  padding: 1rem;
  background-color: #f9f9f9;
}
.app-button,
.modern-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin: 5px;
}
.app-button:hover,
.modern-button:hover{
  background-color: #0056b3;
}
.error-message {
  color: red;
  margin-bottom: 10px;
}

/* Sidebar Styles */
.App-sidebar {
  background-color: #333;
  color: white;
  padding: 2rem;
  box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.1);
  font-size: 1rem;
}
.App-link {
  text-decoration: none;
  color: white;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}
.App-link i {
  margin-right: 8px;
}
.App-link:hover {
  background-color: #555;
}

/* Button Styles */
.button-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 1rem;
}
.modern-button {
  padding: 15px 30px;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.toggle-sidebar-button {
  display: none;
  background: none;
  color: #333;
  border: none;
  font-size: 24px;
}
.app-button.disabled {
  background-color: #ccc;
  color: #888;
  cursor: not-allowed;
}

/* Capture Styles */
.capture-header {
  height: 100%;
}
.capture-input-container {
  flex-direction: column;
  min-width: 200px;
  max-width: 600px;
  padding: 10px;
}
.capture-man-section {
  flex: 1;
  justify-content: center;
}
.capture-input-style {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  width: 100%;
}
.capture-input-label {
  margin-right: 10px;
  display: inline-block;
  margin-bottom: 3px;
}

/* Modal Styles */
.capture-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.capture-modal-content,
.login-container {
  background-color: #fff;
  padding: 2rem;
  border-radius: 5px;
  text-align: center;
}
.capture-modal-content {
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
  overflow-y: auto;
  max-height: 60%;
}
.capture-modal-title {
  font-size: 1.5rem;
  margin-bottom: 15px;
}
.capture-modal-countdown {
  font-size: 3rem;
  margin-bottom: 30px;
}
.readonly-input {
  font-style: italic;
  color: gray;
  background-color: #f5f5d3;
  pointer-events: none;
}

/* Login Styles */
.login-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.login-container {
  width: 300px;
}
.login-input-group {
  margin-bottom: 20px;
}

/* Device Menu Styles */
.device-menu {
  position: absolute;
  background-color: white;
  border: 1px solid #ddd;
  max-height: 150px;
  width: 200px;
  overflow-y: auto;
  z-index: 1000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  appearance: none;
}
.device-menu option {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #eee;
  box-sizing: border-box;
  background-color: white;
}
.device-menu option:hover,
.device-menu option:focus {
  background-color: #f5f5f5;
}
.device-menu option:last-child {
  border-bottom: none;
}

/* Recordings Styles */
.record-container,
.recordings-container {
  padding: 1rem;
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  max-height: 100%;
}
.search-bar,
.recordings-search-bar {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  align-items: center;
}
.search-label,
.recordings-search-label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.pagination-controls,
.recordings-pagination-controls {
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-bottom: 1rem;
}
.recordings-table-container {
  flex: 1;
  overflow-y: auto;
}
.recordings-row {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid #e1e1e1;
}

.recordings-row:hover {
  background-color: #f5f5f5;  /* Light grey background */
  cursor: pointer;  /* Show hand cursor on hover */
}


.recordings-header-row {
  background-color: #f5f5f5;
}
.recordings-cell {
  flex: 1;
  text-align: center;
}

.metadata-flex-container {
  display: flex;
  flex-direction: column;
}

.metadata-row {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0;
}

.metadata-key, .metadata-value {
  width: 50%;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltip-text {
  visibility: hidden;
  width: 200px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%; 
  left: 50%; 
  margin-left: -100px; 
  opacity: 0;
  transition: opacity 2s, visibility 2s;
}

.tooltip:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}


/* Media Queries */
@media (max-width: 768px) {
  .container{
    flex-direction: column;
  }
  .App-sidebar {
    width: 100%;
    text-align: center;
    box-shadow: none;
    padding: 1rem;
    position: absolute;
    top: 40px;
    z-index: 1000;
  }
  .App-sidebar.visible {
    display: block;
  }
  .App-sidebar.hidden{
    display: none;
  }
  .App-link {
    padding: 0.5rem;
    margin-bottom: 5px;
  }
  .toggle-sidebar-button {
    display: block;
  }
  .App-content {
    padding: 1rem 0.5rem;
  }
  .button-wrapper {
    gap: 0.5rem;
  }
  .modern-button {
    padding: 10px 15px;
    font-size: 14px;
  }
  .capture-input-container {
    padding: 5px;
  }
  .capture-input-style {
    padding: 5px;
  }
  .capture-modal-content,
  .login-container {
    padding: 1rem;
  }
  .search-bar,
  .recordings-search-bar {
    gap: 0.5rem;
  }
  .search-label,
  .recordings-search-label {
    flex-direction: column;
    gap: 0.3rem;
  }
  .pagination-controls,
  .recordings-pagination-controls {
    flex-wrap: wrap;
  }
}

/* Translated TypeScript Styles */
.ts-container {
  font-family: Arial, sans-serif;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  background-color: #f5f5f5;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}

.ts-devices-container {
  text-align: left;
  margin: 20px 0;
}

.ts-device {
  cursor: pointer;
  padding: 10px;
  transition: background-color 0.3s;
}

.ts-device:hover {
  background-color: #eaeaea;
}

.ts-modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.ts-modal {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 600px;
  box-sizing: border-box;
}

.ts-input {
  display: block;
  width: 100%;
  margin: 10px 0;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

.ts-button {
  background-color: #007BFF;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.ts-spinner {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
  margin-right: 10px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.ts-payload-editor {
  height: 200px;
  margin-bottom: 10px;
  border: 1px solid black;
  border-radius: 5px;
  padding: 5px;
}

.ts-log-viewer {
  max-height: 200px;
  overflow-y: scroll;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  background-color: #f0f0f0;
  margin-top: 10px;
}

.ts-section-title {
  font-size: 18px;
  font-weight: bold;
}
